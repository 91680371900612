<template>
  <div>
    <h1 class="outline--text">Accounts</h1>
    <v-row>
      <v-col>
        <v-tabs>
          <v-tab :to="{ name: 'AccountListPage' }">Accounts</v-tab>
          <v-tab :to="{ name: 'AccountLogsPage' }">User logs</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <log-search></log-search>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="logs"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"
    >
      <template v-slot:item.accountId="{ item }">
        <span>{{ item.accountId }} {{ item.accountName }}</span>
      </template>
      <template v-slot:item.userId="{ item }">
        <span>{{ item.userId }} {{ item.userName }}</span>
      </template>
      <template v-slot:item.rootSession="{ item }">
        <span>{{ item.rootSession ? "Yes" : "No" }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import LogSearch from "@/views/account/log-list/LogSearch";
import { mapGetters, mapState } from "vuex";

import { FETCH_LOGS, SET_FILTERS } from "@/store/modules/usersLog/actions";

import BaseListPage from "./base/BaseListPage";

import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/usersLog/state";

export default {
  name: "AccountLogsPage",
  extends: BaseListPage,
  components: { LogSearch },
  props: {
    limit: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      isLoading: false,
      countPerPage: [
        {
          attribute: 5,
          label: "5",
        },
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Account",
          value: "accountId",
        },
        {
          text: "User",
          value: "userId",
        },
        {
          text: "Root",
          value: "rootSession",
        },
        {
          text: "Entity ID",
          value: "entityId",
        },
        {
          text: "Entity name",
          value: "entityName",
        },
        {
          text: "Action group",
          value: "actionGroup",
        },
        {
          text: "Action",
          value: "primaryAction",
        },
        {
          text: "Message",
          value: "message",
          width: "400",
        },
        {
          text: "Date",
          value: "createdAt",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("usersLog", [
      "logs",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("usersLog", ["sort", "pagination", "filters"]),

    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`account/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`usersLog/${FETCH_LOGS}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.applyRouteParams();
    this.fetchItems();
  },
};
</script>
